import React, { useRef } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@material-ui/icons";

import SlickSlider from "react-slick";
import * as sliderStyle from "./Slider.css";
import * as sliderTheme from "./Slider-theme.css";

const style = { ...sliderStyle, ...sliderTheme };

const Slider = ({ children, settings, arrowPos }) => {
  const classes = useStyles({ arrowPos });
  const sliderRef = useRef();

  const sliderSettings = {
    dots: true,
    arrows: false,
    // infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) =>
      arrowPos ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.gridContainer}
        >
          {arrowPos && (
            <IconButton
              onClick={slidePrevious}
              edge="start"
              className={`${classes.iconButton} ${classes.leftButton}`}
            >
              <ArrowBackIosRounded />
            </IconButton>
          )}
          <ul style={{ display: "inline", padding: 0, margin: 0 }}>{dots}</ul>
          {arrowPos && (
            <IconButton
              onClick={slideNext}
              edge="end"
              className={`${classes.iconButton} ${classes.rightButton}`}
            >
              <ArrowForwardIosRounded />
            </IconButton>
          )}
        </Grid>
      ) : (
        <ul style={{ marginTop: "10px" }}>{dots}</ul>
      ),
  };

  const combinedSettings = { ...sliderSettings, ...settings };

  const slideNext = () => {
    sliderRef.current.slickNext();
  };

  const slidePrevious = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <SlickSlider
        {...combinedSettings}
        ref={sliderRef}
        className={classes.root}
        style={style}
      >
        {children}
      </SlickSlider>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  gridContainer: {
    marginTop: theme.spacing(2),
  },
  iconButton: {
    color: "black",
    transform: `translateY(4px)`,
    // [theme.breakpoints.up("lg")]: {
    //   transform: `translateY(-280px)`,
    // },
  },
  leftButton: ({ arrowPos }) =>
    arrowPos && {
      [theme.breakpoints.up("lg")]: {
        transform: `translate(${arrowPos.leftButtonOffsetX}px, ${arrowPos.buttonOffsetY}px)`,
        position: "absolute",
        left: 0,
      },
    },
  rightButton: ({ arrowPos }) =>
    arrowPos && {
      [theme.breakpoints.up("lg")]: {
        transform: `translate(${arrowPos.rightButtonOffsetX}px, ${arrowPos.buttonOffsetY}px)`,
        position: "absolute",
        right: 0,
      },
    },
}));

export default Slider;
