import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { alpha, makeStyles } from "@material-ui/core";
import { CardActionArea } from "gatsby-theme-material-ui";
import useLanguage from "../hooks/useLanguage";
import { convertSlashDateToMoment, formatMomentDateByLang } from "../utils";

const FeaturedPostSliderItem = ({ article, light, imageOnLeftTextOnRight }) => {
  const lang = useLanguage();
  const classes = useStyles({ article, light, imageOnLeftTextOnRight });
  const isPhone = useMediaQuery(useTheme().breakpoints.down("xs"));

  let date = "";
  if (article.eventFieldGroup?.eventStartDate) {
    date = formatMomentDateByLang(
      convertSlashDateToMoment(
        article.eventFieldGroup?.eventStartDate.split(" ")[0]
      ),
      lang
    );
  }

  let eventInfo = "";
  if (
    article.eventFieldGroup?.eventStartDate ||
    article.locationGroup?.location
  ) {
    eventInfo = [date, article.locationGroup.location].join(" | ");
  }

  return (
    <CardActionArea
      className={classes.featuredArticleRoot}
      to={article.type === "speech" ? article.speech_pdf_files : article.uri}
      target={article.type === "speech" ? "_blank" : "_self"}
    >
      <div className={classes.featuredArticleContent}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.featuredArticleContentText}
        >
          <Grid item>
            <Typography
              variant={isPhone ? "h5" : "h4"}
              className={classes.heading}
            >
              {article.title}
            </Typography>
          </Grid>

          <Grid item style={{ overflow: "hidden" }}>
            {article.synopsis && (
              <Typography
                variant={isPhone ? "body2" : "body1"}
                className={classes.description}
              >
                {article.synopsis}
              </Typography>
            )}
            {eventInfo && (
              <Typography
                variant={isPhone ? "body2" : "body1"}
                className={classes.description}
              >
                {eventInfo}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    </CardActionArea>
  );
};

const useStyles = makeStyles((theme) => ({
  featuredArticleRoot: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundColor: theme.palette.third.main,

    backgroundImage: ({ article }) =>
      `url(${article.featureImg || article.featuredImage?.node?.sourceUrl || ""
      })`,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: ({ article }) =>
        `url(${article.contentGenericGroup?.mobileFeaturedImage?.sourceUrl || article.mobileFeatureImg || article.featureImg || article.featuredImage?.node?.sourceUrl || ""})`,
    },
    [theme.breakpoints.between("sm", "md")]: {
      backgroundImage: ({ article }) =>
        `url(${article.contentGenericGroup?.tabletFeaturedImage?.sourceUrl || article.tabletFeatureImg || article.featureImg || article.featuredImage?.node?.sourceUrl || ""})`,
    },


    [theme.breakpoints.up("xs")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      backgroundSize: ({ imageOnLeftTextOnRight }) => imageOnLeftTextOnRight ? "45% auto" : "cover",
      backgroundPosition: ({ imageOnLeftTextOnRight }) => imageOnLeftTextOnRight ? "5% 27%" : "center center",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundPosition: ({ imageOnLeftTextOnRight }) => imageOnLeftTextOnRight ? "5% 23%" : "center center",
    },
    [theme.breakpoints.up("1440")]: {
      backgroundPosition: ({ imageOnLeftTextOnRight }) => imageOnLeftTextOnRight ? "5% 47%" : "center center",
    },
  },
  featuredArticleContent: {
    height: "300px",
    [theme.breakpoints.up("sm")]: {
      height: "340px",
      // padding: theme.spacing(4),
      // paddingLeft: `calc(50% + ${theme.spacing(4)}px)`,
    },
    [theme.breakpoints.up("md")]: {
      height: "360px",
      padding: theme.spacing(4),
      paddingLeft: `calc(50% + ${theme.spacing(4)}px)`,
    },
    [theme.breakpoints.up("lg")]: {
      height: "400px",
    },
  },
  featuredArticleContentText: {
    backgroundColor: ({ light }) =>
      light
        ? alpha("#FFFFFF", 0.85)
        : alpha(theme.palette.primary.main, 0.85),
    padding: theme.spacing(3),
    width: "100%",
    overflow: "hidden",
    // display: "flex",
    // flexDirection: "column",
    
    [theme.breakpoints.up("xs")]: {
      position: "absolute",
      top: "200px",
      height: "240px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "200px",
      height: "260px",
    },
    [theme.breakpoints.up("md")]: {
      position: "static",
      height: "100%",
    },
  },
  heading: {
    color: ({ light }) => (light ? theme.palette.primary.main : "white"),
    display: "-webkit-box",
    "-webkit-line-clamp": 5,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",

    marginBottom: theme.spacing(1),
  },
  description: {
    color: ({ light }) => (light ? theme.palette.primary.main : "white"),
  },
}));

export default FeaturedPostSliderItem;
