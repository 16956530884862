import React, { useContext } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slider from "./Slider";
import FeaturedPostSliderItem from "./FeaturedPostSliderItem";

import i18nContext from "../i18n-context";

const FeaturedPostSlider = ({
  posts,
  light,
  arrowPos,
  imageOnLeftTextOnRight,
}) => {
  const classes = useStyles();
  const i18n = useContext(i18nContext);

  return (
    <div className={classes.root} id="featured-post-slider">
      {posts.length > 0 ? (
        <Slider
          settings={{ autoplay: true, autoplaySpeed: 5000 }}
          arrowPos={arrowPos}
        >
          {posts.map((article, index) => {
            return (
              <FeaturedPostSliderItem
                key={index}
                light={light}
                article={article}
                imageOnLeftTextOnRight={imageOnLeftTextOnRight}
              />
            );
          })}
        </Slider>
      ) : (
        <Card
          className={classes.loadingScreen}
          elevation={0}
          variant="outlined"
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className={classes.gridContainer}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography variant="body2" align="center">
                {i18n.loading}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4),
    },
  },
  loadingScreen: {
    height: "420px",
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      height: "370px",
    },
    [theme.breakpoints.up("md")]: {
      height: "360px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "400px",
    },
  },
  gridContainer: {
    width: "100%",
    height: "100%",
  },
}));

export default FeaturedPostSlider;
